import React, { Fragment } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHourglassHalf,
    faMoneyCheckDollar,
    faClipboardCheck,
    faAddressBook,
    faChartGantt
} from "@fortawesome/free-solid-svg-icons";

import VenusTimeSheet from "./VenusTimeSheet";
import VenusMasterPT from "./VenusMasterPT";
import VenusPT from "./VenusPT";
import VenusQuote from "./VenusQuote";

const Venus = (props) => {
    const { access, role, userID, userName} = props;

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<VenusApps path="/" access={access} role={role} />} />
                <Route path="/timesheet" element={<VenusTimeSheet userID={userID} userName={userName} access={access} admin={false} />} />
                <Route path="/employee-timesheets" element={<VenusTimeSheet userID={userID} access={access} admin={true} />} />
                <Route path="/master-pt" element={<VenusMasterPT userID={userID} access={access} admin={false} />} />
                <Route path="/pt" element={<VenusPT userID={userID} access={access} admin={false} />} />
                <Route path="/employee-pts" element={<VenusPT userID={userID} access={access} admin={true} />} />
                <Route path="/quotes" element={<VenusQuote userID={userID} access={access} admin={false} />} />
                <Route path="/employee-quotes" element={<VenusQuote userID={userID} access={access} admin={true} />} />
            </Routes>
        </Fragment>
    )
};

const VenusApps = (props) => {
    const { access, role } = props;
    let navigate = useNavigate();

    const routeChange = (app) => {
        navigate(app);
    }

    return (
        <Fragment>
            <div className="venus-app-container">
                <div className="app-container-label">
                    Time Cards
                </div>
                <div className="venus-app-container-item">
                    {access >= 5 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/employee-timesheets")}>
                            <div className="app-icon-double">
                                <FontAwesomeIcon icon={faAddressBook} className="icon" />
                                <FontAwesomeIcon icon={faHourglassHalf} className="icon" />
                            </div>
                            <div className="appName" style={{ fontSize: "13px" }}>
                                Employee Time Sheets
                            </div>
                        </div>
                        : null
                    }
                    {role >= 2 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/timesheet")}>
                            <div className="appIcon">
                                <FontAwesomeIcon icon={faHourglassHalf} className="icon" />
                            </div>
                            <div className="appName">
                                Time Sheets
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div className="app-container-label">
                    Work Forms
                </div>
                <div className="venus-app-container-item">
                    {role >= 4 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/master-pt")}>
                            <div className="appIcon">
                                <FontAwesomeIcon icon={faChartGantt} className="icon" />
                            </div>
                            <div className="appName">
                                Master Project Tracker
                            </div>
                        </div>
                        : null
                    }

                    {access >= 5 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/employee-pts")}>
                            <div className="app-icon-double">
                                <FontAwesomeIcon icon={faAddressBook} className="icon" />
                                <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
                            </div>
                            <div className="appName" style={{ fontSize: "13px" }}>
                                Employee Project Trackers
                            </div>
                        </div>
                        : null
                    }

                    {access >= 2 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/pt")}>
                            <div className="appIcon">
                                <FontAwesomeIcon icon={faClipboardCheck} className="icon" />
                            </div>
                            <div className="appName">
                                Project Trackers
                            </div>
                        </div>
                        : null
                    }
                </div>
                
                <div className="venus-app-container-item">
                    {role >= 5 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/employee-quotes")}>
                            <div className="app-icon-double">
                                <FontAwesomeIcon icon={faAddressBook} className="icon" />
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="icon" />
                            </div>
                            <div className="appName">
                                Employee Quotes
                            </div>
                        </div>
                        : null
                    }

                    {role >= 1 ?
                        <div className="app" onClick={() => routeChange("/orbit/venus/quotes")}>
                            <div className="appIcon">
                                <FontAwesomeIcon icon={faMoneyCheckDollar} className="icon" />
                            </div>
                            <div className="appName">
                                Quotes
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </Fragment>
    )
};

export default Venus;