import React, {Fragment, useState, useEffect, useRef, useMemo} from "react";
import { useReactToPrint } from 'react-to-print';
import {child, get, getDatabase, ref, set} from "firebase/database";
import {
    Button,
    Label,
    ListGroup,
    ListGroupItem,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";

import {useReactTable, getCoreRowModel, getExpandedRowModel} from "@tanstack/react-table";

import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faCircleCheck,
    faCaretDown,
    faCaretUp,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';

import logo from "../../assets/aw-logo.png";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import moment from "moment";

const realtimeDB = getDatabase();

const getFormattedDate = (dateInput) => {
    if (!dateInput) {
        return "";
    }
    let date = new Date(dateInput.seconds * 1000)

    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

const VenusMasterPT = (props) => {
    const { userID, access, admin } = props;

    const [loading, setLoading] = useState(true);

    const [masterPTName, setMasterPTName] = useState("");
    const [masterPTListInfo, setMasterPTListInfo] = useState([]);
    const [newMasterPTNumber, setNewMasterPTNumber] = useState(0);
    const [masterPTData, setMasterPTData] = useState({});
    const [masterPTInfo, setMasterPTInfo] = useState({});

    const initialData = [
        {
            id: "1",
            customer: "",
            venue: "" ,
            projectName: "" ,
            poLineNumber: "" ,
            nestedRows: [
                {
                    itemNum: "1" ,
                    site: "" ,
                    assignmentDescription: "" ,
                    leadEngineer: "" ,
                    dateAssigned: "" ,
                    dateScheduled: "" ,
                    dateCompleted: "" ,
                    projectHours: "0" ,
                    projectDeadline: "" ,
                    status: "Unassigned" ,
                    activityLog: "" ,
                    notes: ""
                }
            ]
        }
    ];
    const [data, setData] = useState(initialData);

    const [updateMasterPT, setUpdateMasterPT] = useState(false);

    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [deleteParentModal, setDeleteParentModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(-1);

    const [selectedParentRow, setSelectedParentRow] = useState(-1); // Parent row index
    const [selectedNestedRow, setSelectedNestedRow] = useState(-1); // Nested row index
    const [deleteNestedModal, setDeleteNestedModal] = useState(false); // Nested delete modal


    const toggle = () => {
        setModal(!modal);

        if (modal) {
            fetchMasterPTList();
        }
    }
    const toggleNested = () => setNestedModal(!nestedModal);
    const toggleDeleteParent = () => {
        setDeleteParentModal(!deleteParentModal);
    };
    const toggleDeleteNestedModal = () => {
        setDeleteNestedModal(!deleteNestedModal);
    };

    // Get MasterPT List
    const fetchMasterPTList = async () => {
        const dbRef = ref(realtimeDB);
        let latestMasterPT = 0;

        try {
            const snapshot = await get(child(dbRef, "venus_master_project_trackers"));

            if (snapshot.exists()) {
                const masterPTids = Object.keys(snapshot.val());
                // Get Master PT name
                const namesList = masterPTids.map((id) => [snapshot.val()[id].masterPTInfo.masterPTName, snapshot.val()[id].masterPTInfo.masterPTNumber]);

                latestMasterPT = Math.max(...masterPTids.map((id) => Number(id.toString().split("_")[4])));
                setNewMasterPTNumber(latestMasterPT + 1);
                setMasterPTListInfo(namesList);
            } else {
                console.log("No data available");
                setMasterPTListInfo([]);
            }
        } catch (error) {
            console.log(error);
            alert("Error getting Master PT List");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMasterPTList();
    }, []);

    const getMasterPTData = async (masterPTID) => {
        setUpdateMasterPT(true);

        const masterPTRef = ref(realtimeDB, "venus_master_project_trackers/" + masterPTID);
        const snapshot = await get(masterPTRef);

        if (snapshot.exists()) {
            const masterPTData = snapshot.val();

            setMasterPTInfo(masterPTData.masterPTInfo);
            setData(masterPTData.data);
        } else {
            console.log("No data available");
            setMasterPTData({});
        }
    }

    // Create Master Tracker
    const handleCreateMasterPT = (e) => {
        setUpdateMasterPT(false);

        // MasterPT object
        const blankMasterPT = {
            masterPTName: "",
            masterPTNumber: "AW_Master_PT_" + new Date().getFullYear().toString() + "_" + newMasterPTNumber.toString().padStart(4, "0"),
        }

        setData(initialData);
        setMasterPTInfo({ ...blankMasterPT });
    }

    // Columns
    const columns = [
        {
            accessorKey: "id",
            header: "ID #",
            meta: { width: "60px", fontWeight: "bold" }
        },
        {
            accessorKey: "customer",
            header: "Customer",
            meta: { width: "200px" },
            cell: ({ row, column }) => (
                <input
                    type="text"
                    value={row.original[column.id]}
                    style={{ fontWeight: "bold" }}
                    onChange={(e) => {
                        handleCellUpdate(row.index, column.id, e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-input")}
                />
            )
        },
        {
            accessorKey: "venue",
            header: "Venue",
            meta: { width: "200px", fontWeight: "bold" },
            cell: ({ row, column }) => (
                <input
                    type="text"
                    value={row.original[column.id]}
                    style={{ fontWeight: "bold" }}
                    onChange={(e) => {
                        handleCellUpdate(row.index, column.id, e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-input")}
                />
            )
        },
        {
            accessorKey: "projectName",
            header: "Project",
            meta: { width: "200px", fontWeight: "bold" }, cell: ({ row, column }) => (
                <input
                    type="text"
                    value={row.original[column.id]}
                    style={{ fontWeight: "bold" }}
                    onChange={(e) => {
                        handleCellUpdate(row.index, column.id, e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-input")}
                />
            )
        },
        {
            accessorKey: "poLineNumber",
            header: "PO # - Line #",
            meta: { width: "200px" },
            cell: ({ row, column }) => (
                <input
                    type="text"
                    value={row.original[column.id]}
                    style={{ fontWeight: "bold" }}
                    onChange={(e) => {
                        handleCellUpdate(row.index, column.id, e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-input")}
                />
            )
        },
        {
            accessorKey: "info",
            header: "Task Status Information",
            meta: { width: "780px", minWidth: "780px" },
            cell: ({ row }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly", fontWeight: "500", gap: "10px", minWidth: "760px" }}>
                    <div style={row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Unassigned").length > 0 ? { backgroundColor: "darkgray", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "default" } : { color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default"}}>
                        Unassigned: {row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Unassigned").length}
                    </div>
                    <div style={row.original.nestedRows.filter((nestedRow) => nestedRow.status === "In Progress").length > 0 ? { backgroundColor: "yellow", color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default" } : { color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default"}}>
                        In Progress: {row.original.nestedRows.filter((nestedRow) => nestedRow.status === "In Progress").length}
                    </div>
                    <div style={row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Completed").length > 0 ? { backgroundColor: "#198754", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "default" } : { color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default" }}>
                        Completed: {row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Completed").length}
                    </div>
                    <div style={row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Cancelled").length > 0 ? { backgroundColor: "darkgray", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "default" } : { color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default"}}>
                        Cancelled: {row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Cancelled").length}
                    </div>
                    <div style={row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Past Deadline").length > 0 ? { backgroundColor: "red", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "default" } : { color: "black", padding: "5px 10px", borderRadius: "5px", cursor: "default" }}>
                        Past Deadline: {row.original.nestedRows.filter((nestedRow) => nestedRow.status === "Past Deadline").length}
                    </div>
                    <div style={{ backgroundColor: "#007bff", color: "white", padding: "5px 10px", borderRadius: "5px", cursor: "default" }}>
                        Total: {row.original.nestedRows.length}
                    </div>
                </div>
            )
        },
        {
            id: "expander",
            header: "Actions",
            meta: { width: "220px" },
            cell: ({ row }) =>
                row.getCanExpand() && (
                    <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
                        <Button
                            color="primary"
                            width="50px"
                            onClick={row.getToggleExpandedHandler()}
                        >
                            {row.getIsExpanded() ? <FontAwesomeIcon icon={faCaretUp} size={"sm"} /> : <FontAwesomeIcon icon={faCaretDown} size={"sm"} />}
                        </Button>
                        <Button
                            color="success"
                            width="50px"
                            tooltip="Add Row"
                            onClick={() => handleAddNestedRow(row.index)}
                        >
                            <FontAwesomeIcon icon={faPlus} size={"sm"} />
                        </Button>
                        <Button
                            color="warning"
                            width="50px"
                            onClick={() => {
                                setSelectedRow(row.index);
                                toggleDeleteParent();
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashCan} size={"sm"} />
                        </Button>
                    </div>

                ),
        }
    ];

    const nestedColumns = [
        {
            accessorKey: "itemNum",
            header: "Item #",
            meta: { width: "70px" },
            cell: ({ row }) => (
                <div className="venus-master-pt-cell-input-container">
                    <input
                        readOnly={true}
                        type="number"
                        maxLength={2}
                        value={row.original.itemNum || ""}
                        onChange={(e) => {
                            //handleCellUpdate(row.index, column.id, e.target.value);
                            handleNestedRowChange(row.parentRowIndex, row.index, "itemNum", e.target.value);
                        }}
                        className={classNames("venus-master-pt-cell-input")}
                    />
                </div>
            )
        },
        {
            accessorKey: "site",
            header: "Site",
            meta: { width: "200px" },
            cell: ({ row }) => (
                <div className="venus-master-pt-cell-input-container">
                    <input
                        type="text"
                        value={row.original.site || ""}
                        onChange={(e) => {
                            handleNestedRowChange(row.parentRowIndex, row.index, "site", e.target.value);
                        }}
                        maxLength="200"
                        className={classNames("venus-master-pt-cell-input")}
                    />
                </div>
            )
        },
        {
            accessorKey: "assignmentDescription",
            header: "Assignment Description",
            meta: { width: "400px" },
            cell: ({ row }) => (
                <TextareaAutosize
                    value={row.original.assignmentDescription}
                    onChange={(e) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "assignmentDescription", e.target.value)
                    }}
                    minRows={2}
                    className={classNames("venus-master-pt-cell-textarea")}
                />
            )
        },
        {
            accessorKey: "leadEngineer",
            header: "Lead Engineer",
            meta: { width: "200px" },
            cell: ({ row }) => (
                <select
                    value={row.original.leadEngineer || ""}
                    onChange={(e) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "leadEngineer", e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-dropdown")}
                >
                    <option value="" style={{ color: "lightgray !important" }} disabled defaultValue hidden>Select Lead Engineer</option>
                    {leadEngineerOptions.map((leadEngineer) => (
                        <option key={leadEngineer} value={leadEngineer}>
                            {leadEngineer}
                        </option>
                    ))}
                </select>
            )
        },
        { accessorKey: "dateAssigned", header: "Date Assigned", meta: { width: "200px" }, cell: ({ row, column }) => (
                <DatePicker
                    selected={row.original.dateAssigned}
                    onChange={(date) => {
                        //handleDateChangeDateAssigned(row.index, moment(date).format("MM/DD/YYYY"));
                        handleNestedRowChange(row.parentRowIndex, row.index, "dateAssigned", moment(date).format("MM/DD/YYYY"));
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    className={classNames("venus-datepicker")}
                />
            )
        },
        { accessorKey: "dateScheduled", header: "Date Scheduled", meta: { width: "200px" }, cell: ({ row, column }) => (
                <DatePicker
                    selected={row.original.dateScheduled}
                    onChange={(date) => {
                        //handleDateChangeDateScheduled(row.index, moment(date).format("MM/DD/YYYY"));
                        handleNestedRowChange(row.parentRowIndex, row.index, "dateScheduled", moment(date).format("MM/DD/YYYY"));
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    className={classNames("venus-datepicker")}
                />
            )
        },
        { accessorKey: "projectDeadline", header: "Project Deadline", meta: { width: "200px" }, cell: ({ row, column }) => (
                <DatePicker
                    selected={row.original.projectDeadline}
                    onChange={(date) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "projectDeadline", moment(date).format("MM/DD/YYYY"));
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    className={classNames("venus-datepicker")}
                />
            )
        },
        { accessorKey: "projectHours", header: "Project Hours", meta: { width: "100px" },
            cell: ({ row }) => (
                <input
                    type="number"
                    max={3}
                    value={row.original.projectHours}
                    onChange={(e) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "projectHours", e.target.value)

                    }}
                    className={classNames("venus-master-pt-cell-input")}
                />
            )},
        { accessorKey: "dateCompleted", header: "Date Completed", meta: { width: "200px" }, cell: ({ row, column }) => (
                <DatePicker
                    selected={row.original.dateCompleted}
                    onChange={(date) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "dateCompleted", moment(date).format("MM/DD/YYYY"));
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    className={classNames("venus-datepicker")}
                />
            )
        },
        { accessorKey: "status", header: "Status", meta: { width: "200px" }, cell: ({ row }) => (
                <select
                    value={row.original.status}
                    defaultValue="Unassigned"
                    onChange={(e) => {
                        handleNestedRowChange(row.parentRowIndex, row.index, "status", e.target.value);
                    }}
                    className={classNames("venus-master-pt-cell-dropdown")}
                >
                    {statusOptions.map((status) => (
                        <option key={status} value={status}>
                            {status}
                        </option>
                    ))}
                </select>
            )},
        { accessorKey: "activityLog", header: "Activity Log", meta: { width: "400px" }, cell: ({ row }) => (
                <TextareaAutosize
                    value={row.original.activityLog}
                    onChange={(e) => {
                        //handleCellUpdate(row.index, column.id, e.target.value);
                        handleNestedRowChange(row.parentRowIndex, row.index, "activityLog", e.target.value)
                    }}
                    minRows={2}
                    className={classNames("venus-master-pt-cell-textarea")}
                />
            )
        },
        { accessorKey: "notes", header: "Notes", meta: { width: "400px" }, cell: ({ row }) => (
                <TextareaAutosize
                    value={row.original.notes}
                    onChange={(e) => {
                        //handleCellUpdate(row.index, column.id, e.target.value);
                        handleNestedRowChange(row.parentRowIndex, row.index, "notes", e.target.value)
                    }}
                    minRows={2}
                    className={classNames("venus-master-pt-cell-textarea")}
                />
            )
        },
        {
            id: "actionsNested",
            header: "Actions",
            meta: { width: "80px" },
            cell: ({ row }) => (
                <div style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
                    <Button
                        color="warning"
                        width="50px"
                        onClick={() => {
                            setSelectedParentRow(row.parentRowIndex);  // Save parent row index
                            setSelectedNestedRow(row.index);
                            toggleDeleteNestedModal();
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashCan} size={"sm"} />
                    </Button>
                </div>
            )
        }
    ];

    const handleAddRow = () => {
        // Create a new row object with default/empty data
        const newRow = {
            id: (data.length + 1).toString(), // Increment ID based on row count
            customer: "",
            venue: "",
            projectName: "",
            poLineNumber: "",
            nestedRows: [
                {
                    itemNum: "1",
                    site: "",
                    assignmentDescription: "",
                    leadEngineer: "",
                    dateAssigned: "",
                    dateScheduled: "",
                    dateCompleted: "",
                    projectHours: 0,
                    projectDeadline: "",
                    status: "Unassigned",
                    activityLog: "",
                    notes: ""
                }
            ]
        };

        // Update the table data by appending the new row
        setData([...data, newRow]);
    };

    const handleAddNestedRow = (parentRowIndex) => {
        setData((prevData) => {
            const newData = [...prevData];
            const parentRow = newData[parentRowIndex];

            const newNestedRow = {
                itemNum: `${parentRow.nestedRows.length + 1}`,
                site: "" ,
                assignmentDescription: "" ,
                leadEngineer: "" ,
                dateAssigned: "" ,
                dateScheduled: "" ,
                dateCompleted: "" ,
                projectHours: "0" ,
                projectDeadline: "" ,
                status: "Unassigned" ,
                activityLog: "" ,
                notes: ""
            }

            newData[parentRowIndex].nestedRows = [...parentRow.nestedRows, newNestedRow];

            return newData;
        })
    }

    const handleNestedRowChange = (parentRowIndex, nestedRowIndex, field, value) => {
        setData((prevData) => {
            const newData = [...prevData];
            const parentRow = newData[parentRowIndex];
            const nestedRow = [...parentRow.nestedRows];

            nestedRow[nestedRowIndex] = {
                ...nestedRow[nestedRowIndex],
                [field]: value
            };

            if (field === "status") {

            }

            parentRow.nestedRows = nestedRow;
            newData[parentRowIndex] = parentRow;

            return newData;
        })
    }
    
    const handleCellUpdate = (rowIndex, columnId, value) => {
        const updatedData = [...data];
        updatedData[rowIndex][columnId] = value;
        setData(updatedData);
    };

    const handleDeleteParentRow = (parentRowIndex) => {
        setData((prevData) => {
            // Filter out the row at the given index
            const updatedData = prevData.filter((_, index) => index !== parentRowIndex);

            // Update the IDs to be sequential
            return updatedData.map((row, index) => ({
                ...row,
                id: (index + 1).toString(), // Reset ID to match the new sequential index
            }));
        });

        setSelectedRow(-1);
    }

    const handleDeleteParentRowCancel = () => {
        setSelectedRow(-1);
        toggleDeleteParent();
    }

    const handleDeleteNestedRow = () => {
        setData((prevData) =>
            prevData.map((parentRow, parentIndex) => {
                if (parentIndex === selectedParentRow) {
                    const updatedNestedRows = parentRow.nestedRows
                        .filter((_, nestedIndex) => nestedIndex !== selectedNestedRow)
                        .map((nestedRow, newIndex) => ({
                            ...nestedRow,
                            itemNum: (newIndex + 1).toString(), // Reassign sequential item numbers
                        }));

                    return { ...parentRow, nestedRows: updatedNestedRows };
                }
                return parentRow;
            })
        );

        // Reset selection after deletion
        setSelectedParentRow(-1);
        setSelectedNestedRow(-1);
    };

    // Dropdown configuration
    const leadEngineerOptions = ["Avi Morales Sanchez", "Patricia De Borba", "Carlos Hernandez", "Martin Serrato", "German Murillo", "Liliana DeRossi", "Zachary Coon", "Eli Lawrence", "Christian Rodriguez", "Artur Lago-Varjapetian"];
    const statusOptions = ["In Progress", "Completed", "Past Deadline", "Cancelled", "Unassigned"];

    const getRowColor = (status) => {
         switch (status) {
             case "In Progress":
                 return { backgroundColor: "yellow" };
             case "Completed":
                 return { backgroundColor: "#11cf76" };
             case "Past Deadline":
                 return { backgroundColor: "red" };
             case "Cancelled":
                 return { backgroundColor: "gray" };
             default:
                 return { backgroundColor: "transparent" };

         }
     }

    const handleSaveMasterPT = async () => {
        try {
            // reference location in Firebase where table data will be stored
            const masterPTRef = ref(realtimeDB, "venus_master_project_trackers/" + masterPTInfo.masterPTNumber);

            await set(masterPTRef, { masterPTInfo, data, savedAt: Date.now() }); // Save entire table

            toggleNested();
        } catch (error) {
            console.log(error);
            alert("Error saving Master PT");
        }
    };

    const tableInstance = useReactTable({
        data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: (row) => row.original.nestedRows && row.original.nestedRows.length > 0
    });

    return (
        <Fragment>
            <div className="venus-app-header">
                <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>Master Project Trackers</div>
                <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { toggle(); handleCreateMasterPT() }}>+ Create New Master PT</Button>
            </div>

            {masterPTListInfo.length > 0 ?
                <div>
                    <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                        {masterPTListInfo.map((masterPTs) => (
                            <ListGroupItem className="venus-quote-list-item" key={masterPTs} action tag="button" onClick={() => { toggle(); getMasterPTData(masterPTs[1]) }}>
                                <div>
                                    MPT: {masterPTs[0]}
                                </div>
                                <div>
                                    Project Tracker ID: {masterPTs[1]}
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
                :
                <div className="venus-quote-no-quotes">
                    No Project Trackers to display
                </div>
            }

            <Modal isOpen={modal} toggle={toggle} fullscreen={true} backdrop="static">
                <ModalHeader toggle={toggle} style={{ fontWeight: "bold" }}>
                    <div>
                        <img src={logo} alt="logo" style={{ width: "40px", height: "40px", marginRight: "10px" }} />
                        <span style={{ fontSize: "20px", fontWeight: "bold" }}>Master Project Tracker</span>
                    </div>
                </ModalHeader>
                <div className="venus-modal-master-pt-header">
                    <div>
                        <Input
                            type="text"
                            value={masterPTInfo.masterPTName}
                            style={{ fontSize: "24px", fontWeight: "bold", border: "none" }}
                            placeholder="Master Project Name"
                            onChange = {(e) => setMasterPTInfo({ ...masterPTInfo, masterPTName: e.target.value })}
                        />
                    </div>
                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { handleAddRow() }}>+ Add Row</Button>
                </div>

                <div style={{ padding: "0px 0px 0px 0px !important", marginLeft: "10px", marginRight: "10px", height: "calc(100vh - 250px)", overflowY: "auto" }}>
                    <ModalBody className="venus-modal-master-pt-body">
                        <div className="venus-modal-master-pt-table-container">
                            <table className="styled-table">
                                {/*Parent Header*/}
                                <thead>
                                {tableInstance.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th
                                                key={header.id}
                                                style={{
                                                    width: header.column.columnDef.meta?.width,
                                                    minWidth: header.column.columnDef.meta?.minWidth
                                                }}
                                            >
                                                {header.isPlaceholder ? null : header.column.columnDef.header}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                {/*Parent rows*/}
                                <tbody>
                                {tableInstance.getRowModel().rows.map(row => (
                                    <Fragment key={row.id}>
                                        {/* Parent row */}
                                        <tr
                                            style={{
                                                ...getRowColor(row.original.status)
                                            }}
                                        >
                                            {row.getVisibleCells().map(cell => (
                                                <td
                                                    key={cell.id}
                                                    style={{
                                                        width: cell.column.columnDef.meta?.width,
                                                        minWidth: cell.column.columnDef.meta?.minWidth
                                                    }}
                                                >
                                                    {cell.column.columnDef.cell(cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                        {/* Nested Rows as a Nested Table */}
                                        {row.getIsExpanded() && (
                                            <tr>
                                                <td colSpan={columns.length} style={{ overflow: "auto", margin: "0 !important", padding: "0 !important" }}>
                                                    <table className="styled-table">
                                                        {/* Nested table headers */}
                                                        <thead>
                                                            <tr>
                                                                {nestedColumns.map(col => (
                                                                    <th
                                                                        key={col.accessorKey || col.id}
                                                                        style={{ width: col.meta?.width }}
                                                                    >
                                                                        {col.header}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        {/* Nested table rows */}
                                                        <tbody>
                                                            {row.original.nestedRows.map((nestedRow, nestedIndex) => (
                                                                <tr
                                                                    key={nestedIndex}
                                                                    style={{
                                                                        ...getRowColor(nestedRow.status)
                                                                    }}
                                                                >
                                                                    {nestedColumns.map((col) => (
                                                                        <td key={col.accessorKey || col.id}>
                                                                            {col.accessorKey || col.id ? (
                                                                                col.cell({
                                                                                    row: {
                                                                                        ...nestedRow,
                                                                                        index: nestedIndex,
                                                                                        parentRowIndex: row.index,
                                                                                        original: nestedRow
                                                                                    }
                                                                                })
                                                                            ) : null }
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <Modal isOpen={nestedModal} toggle={toggleNested} size="sm">
                        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>Changes successfully saved</ModalBody>
                        <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button color="success" onClick={toggleNested}>
                                <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={deleteParentModal} toggle={toggleDeleteParent} size="sm">
                        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
                            Delete Project with ID #{selectedRow + 1}?
                        </ModalBody>
                        <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button
                                color="danger"
                                onClick={() => {
                                    handleDeleteParentRow(selectedRow);
                                    toggleDeleteParent();
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                color="secondary"
                                onClick={handleDeleteParentRowCancel}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={deleteNestedModal} toggle={toggleDeleteNestedModal} size="sm">
                        <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>
                            {selectedNestedRow > 0 ? `Delete Project #${selectedParentRow + 1} Item #${selectedNestedRow + 1}?` : "Deleting the first row in a Project is not allowed."}
                        </ModalBody>
                        <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {selectedNestedRow > 0 ?
                                <Fragment>
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            handleDeleteNestedRow();
                                            toggleDeleteNestedModal();
                                        }}
                                    >
                                        Delete
                                    </Button>
                                    <Button color="secondary" onClick={toggleDeleteNestedModal}>
                                        Cancel
                                    </Button>
                                </Fragment>
                                :
                                <Fragment>
                                    <Button color="secondary" onClick={toggleDeleteNestedModal}>
                                        Cancel
                                    </Button>
                                </Fragment>
                            }

                        </ModalFooter>
                    </Modal>
                </div>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
                    <Label style={{ fontWeight: "bold", color: "darkgray" }}>{masterPTInfo.masterPTNumber}</Label>
                    <Button color="danger" style={{ fontWeight: "bold", marginRight: "50px" }} onClick={toggle}>Close</Button>
                    <div className="venus-modal-master-pt-footer">
                        <Button color="success" style={{ fontWeight: "bold" }} onClick={() => { handleSaveMasterPT() }}>Save</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default VenusMasterPT;